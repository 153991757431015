export const getFormValues = <T extends string>(
  keys: readonly T[],
  form: Form<T>
) => {
  return keys.reduce(
    (p, c) => {
      p[c] = form.elements[c].value;
      return p;
    },
    {} as Record<T[number], string>
  ) as Record<T, string>;
};
