'use client';

import type React from 'react';
import { useMemo } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { Button } from 'src/components/Button/Button';
import { useBoolean } from 'src/hooks/useBoolean';
import { cn } from 'src/utils/cn';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  ref?: React.Ref<HTMLInputElement>;
  label?: React.ReactNode;
  onInputBlur?: (value: string) => void;
}

// TODO: add click-away listener to toggle password visibility
export const Input = ({
  label,
  className,
  type,
  onBlur,
  children,
  onInputBlur,
  ref,
  ...props
}: InputProps) => {
  const [passwordVisible, { toggle: togglePasswordVisible }] = useBoolean();

  const isPasswordInput = type === 'password';

  const overwrittenInputType = useMemo<React.HTMLInputTypeAttribute>(() => {
    if (type === 'password' && passwordVisible) {
      return 'text';
    }
    return type ?? '';
  }, [type, passwordVisible]);

  const Icon = overwrittenInputType === 'password' ? Eye : EyeOff;

  const handlePasswordShow = () => {
    togglePasswordVisible();
  };

  const handleInputBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if (isPasswordInput) {
      togglePasswordVisible(false);
    }
    if (onBlur) {
      onBlur(e);
    }
    if (onInputBlur && e.target.value !== props.defaultValue) {
      onInputBlur(e.target.value);
    }
  };

  return (
    <div className="relative">
      {label && <label htmlFor={props.id}>{label}</label>}
      <input
        className={cn(
          'w-full bg-backgroundLight px-4 py-2 text-primary',
          'border border-secondary placeholder:font-thin placeholder:text-primary/60',
          'focus:shadow-md focus:outline-primary',
          { 'pr-10': isPasswordInput },
          className
        )}
        ref={ref}
        type={overwrittenInputType}
        onBlur={handleInputBlur}
        {...props}
      />
      {children}
      {isPasswordInput && (
        <Button
          variant="transparent"
          padded={false}
          onClick={handlePasswordShow}
          data-testid="password-toggle"
          tabIndex={-1}
          className={cn(
            'absolute right-2 top-1/2 z-[1] -translate-y-1/2 hover:bg-transparent',
            'active:-translate-y-1/2',
            {
              'translate-y-0 active:-translate-y-0': Boolean(label),
            }
          )}
        >
          <Icon className="text-2xl hover:stroke-2 hover:text-secondary" />
        </Button>
      )}
    </div>
  );
};
